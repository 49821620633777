/*
 * Lila - Multi-purpose Responsive One Page Template
 * Build Date: august 2016
 * Author: Mohamed Hamato
 * Copyright (C) 2016 Mohamed Hamato
 */
/* ------------------------------------- */
/*  TABLE OF CONTENTS
 /* ------------------------------------- */
/*   PRE LOADING                          */
/*   WOW                                 */
/*   Menu                                */
/*  STICKY HEADER                        */
/*   portfolio-filter                    */
/*   pop up                              */
/*   OWL CAROUSEL                        */
/*  Animated progress bars               */
/*    MAPS                               */
/*  TEXT ANIMATE                         */
/*   TEXT ROTATOR                        */
/*   bg Video                            */
/*   COUNTER                             */


/*--------------------------------------------*/
/*  PRE LOADING
 /*------------------------------------------*/
'use strict';
$(window).load(function() {
    $('#loading').delay(350).fadeOut('slow');
});


$(function() {
    //caches a jQuery object containing the header element
    var header = $(".sticky_header");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll <= 100) {
            header.removeClass('opened').addClass("closed");
        } else {
            header.removeClass("closed").addClass('opened');
        }
    });
});


$(document).ready(function() {

    $('textarea').autogrow();

    $('.bxslider').bxSlider({
        'pager': false,
        'auto': false,
        'pause': 500000,
        'speed': 1300,
        'slideWidth': 400,
        'moveSlides': 1,
        'minSlides': 4,
        'maxSlides': 4,
        'randomStart': false
    });


    $(".toggle").on('click', function() {
        var id = $(this).data('id');

        if($("#" + id).is(":hidden")) {
            $(this).find('img').attr('src', '/img/minus.svg');

            $("#" + id).slideDown(800);
        } else {
            $(this).find('img').attr('src', '/img/plus.svg');

            $("#" + id).slideUp(800);
        }
    });


    /* ------------------------------------- */
    /*   Image zoom
     /* ------------------------------------- */
    $('.img-zoom').hover(function() {
        console.log('hoverin');
        $(this).parent().parent().parent().find('img.zoomable').addClass('transition');
 
    }, function() {
        console.log('hoverout');
        $(this).parent().parent().parent().find('img.zoomable').removeClass('transition');
    });


    /* ------------------------------------- */
    /*   wow
     /* ------------------------------------- */
          var wow = new WOW(
             {
                animateClass: 'animated',
                offset: 50,
                mobile: true
             }
           );
          wow.init();

    /* ==============================================
     Smooth Scroll To Anchor
     =============================================== */

    $('.navbar-nav a').on('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 155
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

    $('.navbar-brand').on('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 155
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

    /* ==============================================
     STICKY HEADER
     =============================================== */

        $(window).on('scroll', function () {
            if ($(window).scrollTop == 0) {
                $('.header').removeClass('sticky_header');
            } else {
                $('.header').addClass('sticky_header');
            }
        });


    /* ==============================================
     OWL CAROUSEL
     =============================================== */

    $(".team_carousel").owlCarousel({
        itemsCustom : [
            [0, 1],
            [450,1],
            [720, 2],
            [1000, 3]
        ],
        slideSpeed : 1000,
        pagination:false,
        autoPlay:true
    });


    $(".testimonial_carousel").owlCarousel({
        itemsCustom : [
            [0, 1],
            [600,1],
            [720, 1],
            [1000, 1]
        ],
        slideSpeed : 1000,
        pagination:false,
        autoPlay:true
    });


    $(".blog_carousel").owlCarousel({
        itemsCustom : [
            [0, 1],
            [450,1],
            [720, 2],
            [1000, 3]
        ],
        slideSpeed : 1000,
        pagination:false,
        autoPlay:true
    });


    $(".intro_owl_carousel").owlCarousel({
        itemsCustom : [
            [0, 1],
            [600,1],
            [720, 1],
            [1000, 1]
        ],
        slideSpeed : 1000,
        pagination:false,
        autoPlay:true
    });


    $(".fullscreen-slider").owlCarousel({
        slideSpeed: 350,
        singleItem: !0,
        autoHeight: !1,
        navigation: !0,
        pagination: !0,
        navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        responsive: !0,
        autoPlay:true
    });

    /* ------------------------------------- */
    /* Animated progress bars
     /* ------------------------------------- */

    $('.progress_bar').waypoint(function () {
        $('.progress').each(function () {
            $('.progress .progress-bar').progressbar({
                transition_delay: 1000
            });
        });
    }, {
        offset: '100%',
        triggerOnce: true
    });


    /* --------------------------------------------------------
     MAPS
     ----------------------------------------------------------- */

    google.maps.event.addDomListener(window, 'load', init);
    var map = $('#map');
    var lattuide = map.attr('data-lat');
    var longtuided = map.attr('data-lon');

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 16,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(lattuide, longtuided), // New York

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#6caec9"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lattuide, longtuided),
            map: map,
            title: 'Snazzy!'
        });
    }

});



















